<template>
  <p-modal :visible="true">
    <span slot="title">{{ isInCompareMode ? `Form was ${status}` : 'Edit form' }}</span>
    <div class="edit-forms-modal">
      <div class="remove-block">
        <div class="title">Remove connections:</div>
        <div v-for="(connection, index) in activeConnections" :key="connection" class="remove-block-row">
          <span>{{ connection }}</span>
          <p-button :disabled="isInCompareMode" variant="text" style="font-size: 2rem" @click="deleteConnection(index)"
            ><md-icon name="close"
          /></p-button>
        </div>
      </div>
      <div class="block">
        <p-text-field v-model="form.name" label="Form title" />
      </div>
      <div v-for="(property, index) in form.properties" :key="index" class="block">
        <div class="title">
          <p-button :disabled="isInCompareMode" variant="text" style="font-size: 2rem" @click="deleteProperty(index)"
            ><md-icon name="close"
          /></p-button>
          <span>{{ camelCase(property.name) }}</span>
        </div>
        <div class="row">
          <p-text-field v-model="property.name" :disabled="isInCompareMode" label="Property name" />
          <p-multiselect
            v-model="property.type"
            label="Type"
            :disabled="isInCompareMode"
            :options="['date', 'datetime', 'string', 'number', 'people', 'file', 'formPicker', 'email', 'boolean']"
            @input="onTypeChanged(index)"
          />
          <p-text-field v-model="property.description" :disabled="isInCompareMode" label="Description" />
          <div>
            <div>
              <p-checkbox v-if="property.type !== 'boolean'" v-model="property.required" :disabled="isInCompareMode" label="Required" />
            </div>
            <div>
              <p-checkbox v-model="property.saveTo" :disabled="isInCompareMode" label="Save as milestone variable" />
            </div>
          </div>
        </div>
        <template v-if="property.type">
          <span
            v-if="property.type !== 'date' && property.type !== 'datetime' && property.type !== 'file' && property.type !== 'formPicker' && property.type !== 'boolean'"
            class="label"
            >Value:</span
          >
          <input-with-variables
            v-if="property.type === 'string' || property.type === 'number'"
            v-model="property.value"
            :disabled="isInCompareMode"
            :additional-options="simpleVariables"
            source="workflow-form"
          />
          <due-date-with-variables
            v-if="property.type === 'date' || property.type === 'datetime'"
            v-model="property.value"
            :disabled="isInCompareMode"
            :additional-options="dateVariables"
            :setup-limits="true"
            :limits="property.limits"
            :calendar="property.calendar"
            :type="property.type"
            source="workflow-form"
            @limitsChanged="onLimitsChanged($event, index)"
            @calendarChanged="($event) => onCalendarChanged($event, index)"
          />
          <assignees-with-variables
            v-if="property.type === 'people'"
            v-model="property.value"
            :disabled="isInCompareMode"
            :additional-options="peopleVariables"
            source="workflow-form"
          />
          <FilePicker
            v-if="property.type === 'file'"
            v-model="property.value"
            :disabled="isInCompareMode"
            :multiple="property.multiple"
            :ext="property.ext"
            :additional-options="fileVariables"
            :search="property.search"
            source="workflow-form"
            @extChanged="onExtChanged($event, index)"
            @searchChanged="onSearchChanged($event, index)"
            @multipleChanged="onMultipleChanged(index)"
          />
          <FormPicker v-if="property.type === 'formPicker'" :disabled="isInCompareMode" :tags="property.tags" @input="onTagsChanged($event, index)" />
          <p-multiselect
            v-if="property.type === 'email'"
            v-model="property.value"
            :disabled="isInCompareMode"
            :options="emailVariables"
            :multiple="true"
          />
          <div v-if="property.type === 'boolean'">
            <p-checkbox v-model="property.value" :disabled="isInCompareMode" label="default value"/>
          </div>
        </template>
      </div>
      <p-button color="primary" :disabled="isInCompareMode" @click="addProperty">Add property</p-button>
    </div>
    <div slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Ok</p-button>
    </div>
  </p-modal>
</template>

<script>
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox.vue';
import TextField from '@/components/common/TextField';
import Modal from '@/components/common/Modal';
import MdIcon from '@/components/common/MdIcon.vue';
import Multiselect from '@/components/common/Multiselect';

import InputWithVariables from './parts/InputWithVariables.vue';
import DueDateWithVariables from './parts/DueDateWithVariables.vue';
import AssigneesWithVariables from './parts/AssigneesWithVariables.vue';
import { mapState } from 'vuex';
import FilePicker from './parts/FilePicker.vue';
import FormPicker from './parts/FormPicker.vue';

function camelCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => (index === 0 ? word : word[0] ? `${word[0].toUpperCase()}${word.slice(1)}` : ''))
    .join('');
}

export default {
  components: {
    'p-button': Button,
    'p-checkbox': Checkbox,
    'p-text-field': TextField,
    'p-modal': Modal,
    'p-multiselect': Multiselect,
    MdIcon,
    InputWithVariables,
    DueDateWithVariables,
    AssigneesWithVariables,
    FilePicker,
    FormPicker
  },
  props: {
    connections: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const connections = this.connections.map((connection) => `${connection.input.node.data.props.title}(${connection.input.name})`);
    return {
      form: this.connections[0].data.from
        ? {
            name: this.connections[0].data.from.name,
            properties: this.connections[0].data.from.properties.map((f) => ({
              ...f,
              multiple: f.multiple || false,
              ext: f.ext || null,
              limits: f.limits || null,
              calendar: f.calendar || null
            }))
          }
        : { name: '', properties: [] },
      isInCompareMode: !!this.connections[0].data.status,
      activeConnections: connections,
      activeTab: 0,
      deletedConnections: [],
      status: this.connections[0].data.status
    };
  },
  computed: {
    ...mapState({
      milestone: (s) => s.milestones.item
    }),
    peopleVariables() {
      const people = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'people') : [];
      return [...people.map((d) => d.saveTo)];
    },
    simpleVariables() {
      const resp = {};
      const vars = this.milestone.variables ? this.milestone.variables : [];
      if (vars) {
        resp['milestone.variables'] = vars.map((d) => d.saveTo);
      }
      return resp;
    },
    dateVariables() {
      const dates = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'date' || v.type === 'datetime') : [];

      return [...dates.map((d) => d.saveTo)];
    },
    fileVariables() {
      const files = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'file') : [];

      return [...files.map((d) => d.saveTo)];
    },
    emailVariables() {
      const emails = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'email') : [];

      return ['{{thisTask.emailAttachments}}', ...emails.map((d) => d.saveTo)];
    }
  },

  methods: {
    onConfirm() {
      const source = {
        port: this.connections[0].output.name,
        step: this.connections[0].output.node.id
      };

      const properties = this.form.properties.map((p) => ({
        ...p,
        property: camelCase(p.name),
        saveTo: p.saveTo ? `{{milestone.variables.${camelCase(p.name)}}}` : null,
        source
      }));
      const allVariables = properties.filter((field) => field.saveTo);
      this.$store.commit('milestones/VARIABLES_CHANGED', {
        variables: allVariables,
        source
      });

      this.$emit('confirm', { form: { name: this.form.name, properties }, deletedConnections: this.deletedConnections });
    },
    addProperty() {
      this.form.properties.push({
        name: '',
        description: '',
        type: null,
        value: null,
        saveTo: false,
        required: false,
        multiple: false,
        ext: null,
        search: null,
        tags: null,
        limits: null,
        calendar: null
      });
    },
    deleteProperty(index) {
      this.form.properties.splice(index, 1);
    },

    deleteConnection(index) {
      this.deletedConnections.push(index);
      this.activeConnections.splice(index, 1);
      if (this.activeConnections.length === 0) {
        const source = {
          port: this.connections[0].output.name,
          step: this.connections[0].output.node.id
        };
        this.$emit('confirm', { form: undefined, deletedConnections: this.deletedConnections });
        this.$store.commit('milestones/VARIABLES_CHANGED', {
          variables: [],
          source
        });
      }
    },
    camelCase,
    onTypeChanged(index) {
      this.form.properties[index].value = undefined;
    },
    onMultipleChanged(index) {
      this.form.properties[index].value = null;
      this.form.properties[index].multiple = !this.form.properties[index].multiple;
    },
    onExtChanged(v, index) {
      this.form.properties[index].ext = v;
    },
    onSearchChanged(v, index) {
      this.form.properties[index].search = v;
    },
    onTagsChanged(v, index) {
      this.form.properties[index].tags = v;
    },
    onLimitsChanged(v, index) {
      this.form.properties[index].limits = v;
    },
    onCalendarChanged(v, index) {
      this.form.properties[index].calendar = v;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-forms-modal {
  padding: 0 20px;
  height: 80vh;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }
  .block {
    padding: 10px 0;
    border-bottom: 1px solid white;
    margin-bottom: 10px;
  }

  .remove-block {
    padding: 10px;
    border: 1px solid white;
    margin-bottom: 10px;
    .remove-block-row {
      display: flex;
      align-items: center;
    }
  }
  .label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }
}
.tabs {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  .tab {
    padding: 10px;
    flex: 1;
    border: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
      border-bottom-width: 0;
      cursor: default;
    }
  }
}
</style>
