var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isReady || _vm.isGetCollectionRequestPending)?_c('section',{staticClass:"prompts"},[_c('p-loading')],1):_c('section',{staticClass:"prompts"},[_c('header',[_c('h1',[_vm._v("Prompts")]),_c('section',{staticClass:"action-wrapper"},[(_vm.$hasPermission('prompts.write'))?_c('router-link',{attrs:{"to":"/prompts/create"}},[_vm._v("+ new")]):_vm._e()],1)]),_c('section',[_c('p-list',{attrs:{"name":"prompts","data":_vm.filteredCollection,"total":_vm.total,"metadata":{
        name: { label: 'Name', width: 'minmax(0, 3fr)' },
        description: { label: 'Description', width: 'minmax(0, 3fr)' },
        tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
        organizations: { label: 'Organizations', width: 'minmax(100px, 2fr)', labelCallback: _vm.getOrganizationName },
        isGeneric: { label: 'Generic', width: '60px' },
        modifiedAt: { label: 'Last modification', width: '175px' }
      }},on:{"edit":_vm.edit},scopedSlots:_vm._u([{key:"name",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/prompts/" + (item.id))}},[_vm._v(_vm._s(value))])]}},{key:"__actions",fn:function(ref){
      var item = ref.item;
return [_c('p-button',{attrs:{"variant":"text","title":"Edit this prompt"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" ✎")])]}}])},[_c('template',{slot:"filter"},[_c('div',{staticClass:"tags-wrapper"},[_c('Multiselect',{attrs:{"multiple":true,"placeholder":"Filter by tag...","options":_vm.allTags},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }