<template>
  <section v-if="isGetRequestPending" class="edit-user">
    <p-loading />
  </section>
  <section v-else-if="!item" class="edit-user">
    <p-not-found />
  </section>
  <section v-else class="edit-user">
    <header>
      <h1>Edit User</h1>
      <div>
        <router-link :to="`/people/${$route.params.id}/change-password`">change password</router-link>
        |
        <router-link :to="`/people/${$route.params.id}/permissions`">manage permissions</router-link>
        |
        <router-link :to="`/people/${$route.params.id}/authorization`">manage authorization</router-link>
        <div class="action-wrapper">
          <p-button v-if="$hasPermission('users.write')" color="secondary" :disabled="item.email === identity.email" @click="confirmDelete"
            >Delete</p-button
          >
        </div>
      </div>
      <div></div>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="email" :label="'Email'" autocomplete="off" :disabled="true" />
      </div>
      <div class="form-row">
        <p-text-field v-model="firstName" :label="'First name'" autocomplete="off" :disabled="!$hasPermission('users.write')" />
        <div v-if="!$v.firstName.required" class="error">First name is required</div>
      </div>
      <div class="form-row">
        <p-text-field v-model="lastName" :label="'Last name'" autocomplete="off" :disabled="!$hasPermission('users.write')" />
        <div v-if="!$v.lastName.required" class="error">Last name is required</div>
      </div>
      <div class="form-row">
        <p-tags v-model="assistants" :disabled="!$hasPermission('users.write')" label="Assistants" placeholder="" :options="suggestMember" />
      </div>
      <div class="form-row bot-row">
        <p-checkbox v-model="isBot" label="Is Bot"></p-checkbox>
        <div v-if="isBot && $hasPermission('bot_scripts.read')">
          <div class="script-buttons">
            <div v-if="scriptExists">
              <p-button v-if="$hasPermission('bot_scripts.write')" type="contained" @click="removeScript" @click.prevent
                ><p-icon name="remove"
              /></p-button>
              <p-button type="contained" @click="downloadScript" @click.prevent><p-icon name="download"/></p-button>
            </div>

            <p-file-select
              v-if="$hasPermission('bot_scripts.write')"
              :class="{ newScript }"
              :accept="'.json,.js'"
              :title="title"
              @change="importBackup"
              ><p-icon name="script"
            /></p-file-select>
          </div>
        </div>
      </div>
      <div v-if="isUpdateRequestFailed" class="error">
        Failed to update a user. Check your input and try again. If an error still occurs, please, contact our developers.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button
          v-if="$hasPermission('users.write')"
          color="primary"
          type="submit"
          :disabled="isUpdateRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Update</p-button
        >
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from './../../utils/httpClient';
import { required } from 'vuelidate/lib/validators';

import Loading from '@/components/common/Loading';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import NotFound from '@/components/NotFound';
import CheckBox from '@/components/common/Checkbox.vue';
import Tags from '@/components/common/Tags';
import FileSelect from '@/components/common/FileSelect';
import Icon from '@/components/common/Icon';

export default {
  components: {
    'p-loading': Loading,
    'p-text-field': TextField,
    'p-button': Button,
    'p-not-found': NotFound,
    'p-checkbox': CheckBox,
    'p-tags': Tags,
    'p-file-select': FileSelect,
    'p-icon': Icon
  },
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      isBot: false,
      assistants: [],
      scriptExists: false,
      newScript: null
    };
  },
  computed: {
    ...mapState({
      identity: s => s.identity,
      item: s => s.users.item,
      isGetRequestPending: s => s.users.isGetRequestPending,
      isUpdateRequestPending: s => s.users.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.users.isUpdateRequestFailed,
      isDeleteRequestPending: s => s.users.isDeleteRequestPending,
      isDeleteRequestFailed: s => s.users.isDeleteRequestFailed,
      practitioners: s => s.portfolio.practitioners.collection,
      assistantTeam: s => s.teams.assistantsTeam
    }),
    title() {
      return this.newScript ? `'${this.newScript.name}' ready to upload` : `Select bot script`;
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        await this.$store.dispatch('users/getById', this.$route.params.id);
        await this.$store.dispatch('teams/getAssistants', this.item.email);

        this.email = this.item && this.item.email;
        this.firstName = this.item && this.item.firstName;
        this.lastName = this.item && this.item.lastName;
        this.isBot = this.item && this.item.isBot;
        this.assistants = this.assistantTeam ? [...this.assistantTeam.members] : [];

        if (this.isBot) {
          this.scriptExists = await this.$store.dispatch('bots/scriptExists', this.email);
        }
      },
      immediate: true
    }
  },
  async created() {
    await this.$store.dispatch('portfolio/practitioners/getCollection');
  },
  methods: {
    async importBackup([file] = []) {
      if (file) {
        this.newScript = file;
      }
    },
    async removeScript() {
      if (this.scriptExists) {
        this.scriptExists = !(await this.$store.dispatch('bots/deleteScript', this.email));
      }
    },
    async downloadScript() {
      const script = await this.$store.dispatch('bots/getScript', this.email);

      if (!script) {
        return;
      }

      var fileURL = window.URL.createObjectURL(script);
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${this.email.split('@')[0]}.json`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        await this.$store.dispatch('users/update', {
          id: this.$route.params.id,
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          isBot: this.isBot,
          assistants: this.assistants
        });
        if (!this.assistantTeam) {
          const team = await this.$store.dispatch('teams/create', {
            name: `${this.item.email.split('@')[0]} assistants`,
            members: this.assistants,
            assistantsTeam: true
          });
          await this.handleAsistantsChange(team.id);
        } else {
          await this.handleAsistantsChange(this.assistantTeam.id);
        }

        if (this.isBot && this.newScript) {
          await this.$store.dispatch('bots/uploadScript', {
            email: this.email,
            script: this.newScript
          });
        }
      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Update failed',
          message: message || `Please, try again later or contact our development team.`
        });
      } finally {
        if (!this.isUpdateRequestFailed) {
          this.$router.push({ path: `/people` });
        }
      }
    },
    async handleAsistantsChange(teamId) {
      const adddedAssistants = this.assistants.filter(a => !this.assistantTeam.members.includes(a));
      const removedAssistants = this.assistantTeam.members.filter(a => !this.assistants.includes(a));
      const addPromises = adddedAssistants.map(email =>
        this.$store.dispatch('teams/addMember', {
          teamId,
          email
        })
      );
      const removePromises = removedAssistants.map(email =>
        this.$store.dispatch('teams/removeMember', {
          teamId,
          email
        })
      );
      await Promise.all([...addPromises, ...removePromises]);
    },
    back() {
      this.$router.back();
    },
    async suggestMember(s) {
      if (s?.length) {
        return httpClient.get('/api/auth/suggest/users?q=' + s);
      } else {
        return [];
      }
    },
    async confirmDelete() {
      const { email, firstName, lastName } = this.item;
      const fullName = [firstName, lastName].join(' ');

      const confirmResult = await this.$confirm({
        title: 'Delete user?',
        message: `Are you sure you want to delete ${fullName} (${this.item.email})? This action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();

      await this.$store.dispatch('users/delete', this.$route.params.id);

      lock.release();

      if (this.isDeleteRequestFailed) {
        this.$toast.error({
          title: 'Failed to delete user',
          message: `Please, try again later or contact our development team.`
        });
      } else {
        this.$toast.success({
          title: 'User deleted',
          message: `User ${fullName} (${email}) was successfully deleted.`
        });
        this.$router.push({ path: `/people` });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-user {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
    position: relative;

    a {
      font-size: 0.8rem;
    }

    .action-wrapper {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .bot-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    height: 40px;

    .script-buttons {
      display: flex;
      justify-content: flex-end;

      .newScript {
        background: var(--theme-highlight);
        border-radius: 5px;
      }

      form {
        padding: 0;
      }
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
