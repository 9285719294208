import { cruduex, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_PERMISSION_COLLECTION = am(`GET_PERMISSION_COLLECTION`);

export default {
  namespaced: true,
  ...cruduex(
    'users',
    {
      async getById(id) {
        const collection = await this.getCollection();

        return collection.data.find(item => item.id === id);
      },
      async getCollection() {
        const data = await httpClient.get(`/api/auth/users`);

        return {
          data
        };
      },
      async create(obj) {
        return httpClient.post(`/api/auth/users`, obj);
      },
      async update(id, obj) {
        return httpClient.patch(`/api/auth/users/${id}`, obj);
      },
      async delete(id) {
        return httpClient.delete(`/api/auth/users/${id}`);
      }
    },
    {
      permissions: {
        namespaced: true,
        state: {
          isRequestPending: false,
          isRequestFailed: false,
          collection: []
        },
        mutations: {
          [GET_PERMISSION_COLLECTION.STARTED](state) {
            state.isRequestPending = false;
            state.isRequestFailed = false;
            state.collection = [];
          },
          [GET_PERMISSION_COLLECTION.FAILED](state) {
            state.isRequestPending = false;
            state.isRequestFailed = true;
          },
          [GET_PERMISSION_COLLECTION.COMPLETED](state, { collection }) {
            state.isRequestPending = false;
            state.collection = collection;
          }
        },
        actions: {
          async getCollection({ commit }) {
            try {
              commit(GET_PERMISSION_COLLECTION.STARTED);
              const collection = await httpClient.get(`/api/auth/permissions/list`);

              commit(GET_PERMISSION_COLLECTION.COMPLETED, { collection });
            } catch (e) {
              commit(GET_PERMISSION_COLLECTION.FAILED);
            }
          }
        }
      }
    }
  )
};
